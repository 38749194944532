import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";

import parse from 'html-react-parser';

const PrivacyPolicy = () => {
  const data  = useStaticQuery(graphql`
  query PrivacyPolicyPage {
    allWpPage(filter: {title: {eq: "Privacy Policy"}}) {
      nodes {
        title
        privacyPolicyPage {
          privacyPolicyContent
        }
      }
    }
  }
`);

  let privacyPolicyData = data.allWpPage.nodes[0];
  let privacyPolicyPageData = privacyPolicyData.privacyPolicyPage;
  let privacyPolicyContent = privacyPolicyPageData.privacyPolicyContent;
  let pageTitle = privacyPolicyData.title;

  return (
    <Layout>
      <Seo title="Contact Us"/>
      <section className="container privacy-policy-page page-default">
        <div className="text-center text-lg-start">
          <h1 className="display-2 pt-5">{  pageTitle }</h1>
        </div>
        <div className="privacy-policy-page-content pt-5">
          <p>{ parse(privacyPolicyContent) }</p>
        </div>
      </section>
    </Layout>
  );
}

export default PrivacyPolicy;